import * as echarts from 'echarts'

const axisLabel = {
  fontSize: 8,
  interval: 0
}
// const grid = { // 图例位置
//   y: '10%',
//   left: '1%',
//   right: '3%',
//   bottom: '3%',
//   height: '90%',
//   containLabel: true
// }

// 总线索
export const totalClue = (box, ratio, data) => {
  const myChart = echarts.init(document.getElementById(box))
  myChart.setOption({
    title: {
      text: `同比${ratio}%`,
      x: 'right',
      y: '-4px',
      textStyle: {
        color: 'rgba(255,255,255)',
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    xAxis: {
      show: false,
      type: 'category',
      boundaryGap: false,
      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: 'rgba(255,255,255,.7)'
        }
      },
      axisLabel: axisLabel,
      data: data.dateTimeList
    },
    yAxis: {
      type: 'value',
      show: false
    },
    grid: {
      y: '10%',
      left: 'left',
      // right: '3%',
      bottom: '3%',
      height: '90%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: '#fff',
          type: 'dashed' // 鼠标移入时的指示线 虚线/实线
        },
        label: {
          backgroundColor: 'rgba(255,255,255,.7)'
        }
      }
    },
    series: [
      {
        data: data.totalNumList,
        type: 'line',
        barGap: '10%', // 条形间
        areaStyle: {},
        showSymbol: false,
        itemStyle: {
          normal: {
            lineStyle: {
              color: '#fff'
            },
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(255,255,255,.5)' },
              { offset: 1, color: 'rgba(0, 83, 205,.7)' }
            ])
          }
        },
        smooth: true
      }
    ]
  })
}

// 无效线索
export const invalidClue = (box, ratio, data) => {
  const myChart = echarts.init(document.getElementById(box))
  myChart.setOption({
    title: {
      text: `同比${ratio}%`,
      x: 'right',
      y: '-4px',
      textStyle: {
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: 'rgba(255,255,255,.7)'
        }
      },
      axisLabel: axisLabel,
      data: data.dateTimeList
    },
    yAxis: {
      type: 'value',
      show: false
    },
    grid: {
      y: '10%',
      left: 'left',
      // right: '3%',
      bottom: '3%',
      height: '90%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: 'rgb(74, 126, 208)',
          type: 'dashed' // 鼠标移入时的指示线 虚线/实线
        },
        label: {
          backgroundColor: 'rgba(255,255,255,.7)'
        }
      }
    },
    series: [
      {
        data: data.totalNumList,
        type: 'line',
        barGap: '10%', // 条形间
        areaStyle: {},
        showSymbol: false,
        itemStyle: {
          normal: {
            lineStyle: {
              color: 'rgb(74, 126, 208)'
            },
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(74, 126, 208,.7)' },
              { offset: 1, color: 'rgba(255,255,255,.5' }
            ])
          }
        },
        smooth: true
      }
    ]
  })
}

// 有效线索
export const effectiveClue = (box, ratio, data) => {
  const myChart = echarts.init(document.getElementById(box))
  myChart.setOption({
    title: {
      text: `同比${ratio}%`,
      x: 'right',
      y: '-4px',
      textStyle: {
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: 'rgba(255,255,255,.7)'
        }
      },
      axisLabel: axisLabel,
      data: data.dateTimeList
    },
    yAxis: {
      type: 'value',
      show: false
    },
    grid: {
      y: '10%',
      left: 'left',
      // right: '3%',
      bottom: '3%',
      height: '90%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: 'rgb(74, 126, 208)',
          type: 'dashed' // 鼠标移入时的指示线 虚线/实线
        },
        label: {
          backgroundColor: 'rgba(255,255,255,.7)'
        }
      }
    },
    series: [
      {
        data: data.totalNumList,
        type: 'line',
        barGap: '10%', // 条形间
        areaStyle: {},
        showSymbol: false,
        itemStyle: {
          normal: {
            lineStyle: {
              color: 'rgba(74, 126, 208)'
            },
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(74, 126, 208,.7)' },
              { offset: 1, color: 'rgba(255,255,255,.5' }
            ])
          }
        },
        smooth: true
      }
    ]
  })
}

// 线索分类统计
export const clueType = (box, data) => {
  const myChart = echarts.init(document.getElementById(box))
  myChart.setOption({
    tooltip: {
      trigger: 'axis'
    },
    color: ['#00BAD1', '#FE5534', '#000', '#004DEC', '#0f0', '#f00', '#f0f', '#00f'],
    legend: {
      data: data.titleList,
      textStyle: {
        fontSize: 12
      }
    },
    toolbox: {
      x: 'left'
    },
    grid: {
      left: '3%',
      right: '5%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.dateTime
    },
    yAxis: {
      type: 'value'
    },
    series: data.data
  })
}

// 推广线索统计
export const clueStatistics = (box, data, num, ratio) => {
  const myChart = echarts.init(document.getElementById(box))
  myChart.setOption({
    title: {
      text: String(num),
      subtext: `占比总数${ratio}%`,
      x: 'center',
      y: 'center',
      itemGap: -5,
      textStyle: {
        fontSize: 30
      },
      subtextStyle: {
        color: '#000',
        fontSize: 20,
        y: 10
      }
    },
    color: ['#11AD70', '#7865F2', '#4D86F9', '#F2573E', '#9A60B4', '#506EC7', '#E97CCD', '#90CC77', '#73BFDD', '#FB834F', '#FAC857'],
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} <br/>({d}%)'
    },
    legend: { // 目录展示
      show: false,
      top: '5%',
      right: 5,
      orient: 'vertical'
    },
    series: [
      {
        name: '具体数据',
        type: 'pie',
        radius: ['55%', '90%'],
        label: {
          formatter: '{d}',
          show: false,
          normal: {
            position: 'center',
            show: false,
            formatter: '{c}\n占比总数{d}%'
          },
          // position: 'center',
          // formatter: function () {
          //   return '   年度总收入 \r\n 100000'
          // },
          textStyle: {
            fontSize: 24,
            align: 'center'
          }
        },
        labelLine: {
          show: false // 数据指示线
        },
        center: ['50%', '52%'], // 图形位置
        // avoidLabelOverlap: false,
        // hoverAnimation: false, // 鼠标移入时显示数据
        itemStyle: {
          borderRadius: 13,
          borderColor: '#fff',
          borderWidth: 3 // 区域块间隙
        },
        emphasis: { // 移入时特效
          label: {
            show: false,
            fontSize: 22,
            align: 'center',
            // formatter: '{d}%',
            fontWeight: 'bold'
          }
        },
        data: data
      }
    ]
  })
}
