<template>
  <div style="display:flex;flex-direction: column;height:100%">
    <div class="top-content">
      <div style="display:flex;justify-content: space-between;">
        <h3 class="title">
          <img src="@/assets/images/marketing/analysis.png">
          总线索统计展示
        </h3>
        <div>
          是否重复：
          <a-select @change="isRepetitiveChange" v-model:value="BooleanFlagEnum"
            :options="$store.state.enumAll.BooleanFlagEnum" placeholder="是否重复" :changeOnSelect="true"
            style="100px;margin-right:10px" />

          部门机构：<a-select v-model:value="screening.orgId" style="width: 140px;margin-right:10px;" placeholder="选择部门"
            @change="departmentChange">
            <a-select-option v-for="item in orgList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>

          日期区间：<a-select v-model:value="screening.timeUnit" style="width: 150px;margin-right:10px;" placeholder="今天"
            @change="timeChange">
            <a-select-option v-for="item in $store.state.enumAll.date" :key="item.label"
              :value="item.value">{{ item.label }}</a-select-option>
          </a-select>

          自定义查询：
          <a-range-picker v-model:value="chooseTime" id="dateChoose" size="default" style="width:200px;"
            @change="dateChange" />
        </div>
      </div>
      <div style="display: flex; justify-content: space-between;">
        <!-- 总线索 -->
        <div class="total-clue">
          <!-- 内容 -->
          <div class="content">
            <div class="content-data" style="padding-bottom:15px">
              <div>总线索</div>
              <div>
                <span style="font-size: 30px">{{ totalClue.totalNumber }}</span>
                <span>条</span>
              </div>
            </div>
            <div style="text-align:end;flex:1;">
              <div style="flex: 1;height: 90px;" id="total"></div>
            </div>
          </div>
        </div>
        <!-- 无效线索 -->
        <div class="invalid-clue">
          <!-- 内容 -->
          <div class="content">
            <div class="content-data">
              <div>无效线索</div>
              <div>
                <span style="font-size: 30px">{{ invalidClue.totalNumber }}</span>
                <span>条</span>
              </div>
              <div>占比{{ invalidClue.proportion }}%</div>
            </div>
            <div style="text-align:end;flex:1;">
              <div style="flex: 1;height: 90px;" id="invalid"></div>
            </div>
          </div>
        </div>
        <!-- 有效线索 -->
        <div class="effective-clue">
          <!-- 内容 -->
          <div class="content">
            <div class="content-data">
              <div>有效线索</div>
              <div>
                <span style="font-size: 30px">{{ effectiveData.totalNumber }}</span>
                <span>条</span>
              </div>
              <div>占比{{ effectiveData.proportion }}%</div>
            </div>
            <div style="text-align:end;flex:1">
              <div style="flex: 1;height: 90px;" id="effective"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-content">
      <div class="type-statistics">
        <div style="display:flex;justify-content: space-between;">
          <h3 class="title">
            <img src="@/assets/images/marketing/type.png">
            线索分类统计
          </h3>
        </div>

        <div class="total-data">
          <div class="data" @click="onClick(0)">
            <div class="top-box" style="border-bottom:1px solid #ccc;padding:10px">
              <div class="data-style"><span>主叫总线索</span><span class="font-style">{{ phoneClueShows.totalNumber }}</span>
              </div>
              <div class="data-style" style="font-size:12px;"><span>占比总数{{ phoneClueShows.proportion }}%</span>
                <span>同比<span
                    :style="{ color: phoneClueShows.ratio <= 0 ? '#FC512E' : '#28B47B', fontWeight: 'bold' }">{{ phoneClueShows.ratio }}%</span></span>
              </div>
            </div>
            <div class="bottom-box" style="padding:0 10px 10px 10px">
              <div class="data-style"><span>无效线索</span><span
                  class="font-style">{{ infinitePhoneClueShows.totalNumber }}</span></div>
              <div class="data-style" style="font-size:12px;"><span>占比总数{{ infinitePhoneClueShows.proportion }}%</span>
                <span>同比<span
                    :style="{ color: infinitePhoneClueShows.ratio <= 0 ? '#FC512E' : '#28B47B', fontWeight: 'bold' }">{{ infinitePhoneClueShows.ratio }}%</span></span>
              </div>
            </div>
          </div>
          <div class="data" @click="onClick(1)">
            <div class="top-box" style="border-bottom:1px solid #ccc;padding-bottom:10px;padding:10px">
              <div class="data-style"><span>表单总线索</span><span class="font-style">{{ formClueShows.totalNumber }}</span>
              </div>
              <div class="data-style" style="font-size:12px;"><span>占比总数{{ formClueShows.proportion }}%</span>
                <span>同比<span
                    :style="{ color: formClueShows.ratio <= 0 ? '#FC512E' : '#28B47B', fontWeight: 'bold' }">{{ formClueShows.ratio }}%</span></span>
              </div>
            </div>
            <div class="bottom-box" style="padding:0 10px 10px 10px">
              <div class="data-style"><span>无效线索</span><span
                  class="font-style">{{ infiniteFormClueShows.totalNumber }}</span></div>
              <div class="data-style" style="font-size:12px;"><span>占比总数{{ infiniteFormClueShows.proportion }}%</span>
                <span>同比<span
                    :style="{ color: infiniteFormClueShows.ratio <= 0 ? '#FC512E' : '#28B47B', fontWeight: 'bold' }">{{ infiniteFormClueShows.ratio }}%</span></span>
              </div>
            </div>
          </div>
          <div class="data" @click="onClick(2)">
            <div class="top-box" style="border-bottom:1px solid #ccc;padding-bottom:10px;padding:10px">
              <div class="data-style"><span>在线总线索</span><span class="font-style">{{ onlineClueShows.totalNumber }}</span>
              </div>
              <div class="data-style" style="font-size:12px;"><span>占比总数{{ onlineClueShows.proportion }}%</span>
                <span>同比<span
                    :style="{ color: onlineClueShows.ratio <= 0 ? '#FC512E' : '#28B47B', fontWeight: 'bold' }">{{ onlineClueShows.ratio }}%</span></span>
              </div>
            </div>
            <div class="bottom-box" style="padding:0 10px 10px 10px">
              <div class="data-style"><span>无效线索</span><span
                  class="font-style">{{ infiniteOnlineClueShows.totalNumber }}</span></div>
              <div class="data-style" style="font-size:12px"><span>占比总数{{ infiniteOnlineClueShows.proportion }}%</span>
                <span>同比<span
                    :style="{ color: infiniteOnlineClueShows.ratio <= 0 ? '#FC512E' : '#28B47B', fontWeight: 'bold' }">{{ infiniteOnlineClueShows.ratio }}%</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="data-echarts" id="typeStatistics" style="flex:1;height:100%">
        </div>
      </div>

      <div style="flex:1;display:flex;flex-direction: column;">
        <div class="clue-statistics">
          <div style="display:flex;justify-content: space-between;">
            <h3 class="title">
              <img src="@/assets/images/marketing/strategy.png">
              各渠道推广线索统计
            </h3>
            <div>
              <a-radio-group v-model:value="chooseType" @change="chooseChange">
                <a-radio :value="1">单选</a-radio>
                <a-radio :value="2">多选</a-radio>
              </a-radio-group>
              <!-- <a-select allowClear v-model:value="screening.orgId" style="width: 120px;margin-right:10px;" placeholder="选择部门" @change="departmentChange">
                <a-select-option v-for="item in orgList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>

              <a-select allowClear value="今天" style="width: 150px;margin-right:10px;" @change="popularizeChange">
                <a-select-option value="day">今天</a-select-option>
                <a-select-option value="week">本周</a-select-option>
                <a-select-option value="month">本月</a-select-option>
                <a-select-option value="year">本年</a-select-option>
              </a-select>

              <a-range-picker size="default" style="width:200px;" @change="popularizeDateChange" /> -->
            </div>
          </div>

          <div style="display:flex;flex-direction: column;flex:1">
            <!-- 渠道推广标题安选/多选按钮 -->
            <div v-show="chooseType === 2" class="ant-radio-group ant-radio-group-solid ant-radio-group-default">
              <div class="ant-radio-button-wrapper" style="margin-top:5px;" v-for="(item, index) in channelList"
                :key="item.id" :value="item.id" @click="channelIdsChange(item, index)">{{ item.name }}</div>
            </div>

            <a-radio-group v-show="chooseType === 1" v-model:value="channelId" button-style="solid" @change="channelChange">
              <a-radio-button style="margin-top:5px;" v-for="(item) in channelList" :key="item.id"
                :value="item.id">{{ item.name }}</a-radio-button>
            </a-radio-group>

            <div class="data-style" style="height:100%">
              <div
                style="display:flex;flex-direction: column;justify-content: space-between;height:100%;width:50%;margin-right:10%">
                <div style="flex:1;height:200px" id="dataEcharts"></div>
                <div class="data-style">
                  <div>
                    <div>主叫线索</div>
                    <div><span class="font-style">{{ popularizeData.phoneClueNum }}</span>条</div>
                    <div>占比总数 {{ popularizeData.phoneClueProportion }}%</div>
                  </div>
                  <div>
                    <div>表单线索</div>
                    <div><span class="font-style">{{ popularizeData.formClueNum }}</span>条</div>
                    <div>占比总数 {{ popularizeData.formClueProportion }}%</div>
                  </div>
                  <div>
                    <div>在线线索</div>
                    <div><span class="font-style">{{ popularizeData.onlineClueNum }}</span>条</div>
                    <div>占比总数 {{ popularizeData.onlineClueProportion }}%</div>
                  </div>
                  <div>
                    <div>无效汇总</div>
                    <div><span class="font-style">{{ popularizeData.invalidClueTotalNum }}</span>条</div>
                    <div>占比总数 {{ popularizeData.invalidClueProportion }}%</div>
                  </div>
                </div>
              </div>
              <div style="flex:1;height:380px;overflow-y:scroll;padding:15px;">
                <div class="department" v-for="(item, index) in popularizeData.channelSubShowList"
                  :key="item.channelSubId">
                  <span>{{ item.channelSubName }} </span>
                  <a-progress style="flex:1;margin-left:10px;" :stroke-color="'rgb(' + colorArr[index] + ')'"
                    :trailColor="'rgba(' + colorArr[index] + ',.3)'" :showInfo="false"
                    :percent="Math.round(item.invalidNum / item.totalNum * 10000) / 100" />
                  <div class="schedule-data"
                    :style="{ width: Math.round(item.invalidNum / item.totalNum * 10000) / 100 < 80 ? (100 - 10) - 40 + 10 + '%' : (100 - 10) - 50 + '%' }">
                    <span>无效{{ item.invalidNum }}</span>
                    <span style="color:#C3C4C6">{{ item.totalNum }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-style" style="height:15%;margin-top:15px;background:#fff">
          <div style="flex:1;padding:8px;margin:5px;background:#eee">
            <div>0:00-8:59</div>
            <div><span class="font-style">{{ popularizeData.earlyMorning }}</span>条</div>
          </div>
          <div style="flex:1;padding:8px;margin:5px;background:#eee">
            <div>9:00-13:59</div>
            <div><span class="font-style">{{ popularizeData.morning }}</span>条</div>
          </div>
          <div style="flex:1;padding:8px;margin:5px;background:#eee">
            <div>14:00-17:59</div>
            <div><span class="font-style">{{ popularizeData.afternoon }}</span>条</div>
          </div>
          <div style="flex:1;padding:8px;margin:5px;background:#eee">
            <div>18:00-19:59</div>
            <div><span class="font-style">{{ popularizeData.evening }}</span>条</div>
          </div>
          <div style="flex:1;padding:8px;margin:5px;background:#eee">
            <div>20:00-23:59</div>
            <div><span class="font-style">{{ popularizeData.lateEvening }}</span>条</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { totalClue, invalidClue, effectiveClue, clueType, clueStatistics } from './echarts/echarts'
import { list as orgList } from '@/api/system/organzition'
import { typeClue, clueChart, channelClueShows, channelList, totalClueShowsAll } from '@/api/marketing/statistics'
import { conversionN, getTimeYMD } from '@/utils/util'
import { message } from 'ant-design-vue'

export default {

  setup () {
    const store = useStore()
    const state = reactive({
      channelIds: [],
      channelId: '',
      channelIdTow: '',
      chooseType: 1,
      BooleanFlagEnum: 0,
      num: 0,
      totalClue: {}, // 总线索展示-总线索
      invalidClue: {}, // 总线索展示-无效线索
      effectiveData: {}, // 总线索展示-有效线索
      phoneClueShows: {}, // 线索分类统计-主叫总线索
      formClueShows: {}, // 线索分类统计-表单总线索
      onlineClueShows: {}, // 线索分类统计-在线总线索
      infinitePhoneClueShows: {},
      infiniteFormClueShows: {},
      infiniteOnlineClueShows: {},
      popularizeData: {},
      clueTypeChart: [],
      colorArr: [],
      chooseTime: [],
      channelList: [], // 渠道列表
      screening: { // 展示筛选
        orgId: '',
        timeUnit: 1,
        isRepetitive: { label: '否', value: 0 },
        queryDate: getTimeYMD()
      },
      typeClueData: { // 线索分类统计筛选
        orgId: '',
        timeUnit: 1
      },
      orgList: []
    })
    // 总线索展示
    // const reload = () => {
    //   let totalRatio = ''
    //   let invalidRatio = ''
    //   let effectiveRatio = ''
    //   // 总线索
    //   getTotalClue(state.screening)
    //     .then(res => {
    //       if (res.code === 10000) {
    //         state.totalClue = res.data
    //         totalRatio = res.data.ratio
    //       }
    //     })
    //   // 无效线索
    //   getTotalClue({ ...state.screening, isEffective: { label: '无效线索', value: 0 } })
    //     .then(res => {
    //       if (res.code === 10000) {
    //         state.invalidClue = res.data
    //         invalidRatio = res.data.ratio
    //       }
    //     })
    //   // 有效线索
    //   getTotalClue({ ...state.screening, isEffective: { label: '有效线索', value: 1 } })
    //     .then(res => {
    //       if (res.code === 10000) {
    //         state.effectiveData = res.data
    //         effectiveRatio = res.data.ratio
    //       }
    //     })
    //   // 总线索曲线图
    //   getTotalEcharts(state.screening)
    //     .then(res => {
    //       if (res.code === 10000) {
    //         res.data.ratio = totalRatio
    //         totalClue('total', res.data)
    //       }
    //     }).catch(err => { console.log(err) })
    //   // 无效线索曲线图
    //   getTotalEcharts({ ...state.screening, isEffective: { label: '无效线索', value: 0 } })
    //     .then(res => {
    //       if (res.code === 10000) {
    //         res.data.ratio = invalidRatio
    //         invalidClue('invalid', res.data)
    //       }
    //     }).catch(err => { console.log(err) })
    //   // 有效线索曲线图
    //   getTotalEcharts({ ...state.screening, isEffective: { label: '有效线索', value: 1 } })
    //     .then(res => {
    //       if (res.code === 10000) {
    //         res.data.ratio = effectiveRatio
    //         effectiveClue('effective', res.data)
    //       }
    //     }).catch(err => { console.log(err) })
    // }
    const reload = () => {
      // 测试接口(整合)
      totalClueShowsAll(state.screening)
        .then(res => {
          if (res.code === 10000) {
            state.totalClue = res.data.totalClue
            state.invalidClue = res.data.invalidTotalClue
            state.effectiveData = res.data.effectiveTotalClue
            totalClue('total', res.data.totalClue.ratio, res.data.totalClueLineChart)
            invalidClue('invalid', res.data.invalidTotalClue.ratio, res.data.invalidTotalClueLineChart)
            effectiveClue('effective', res.data.effectiveTotalClue.ratio, res.data.effectiveTotalClueLineChart)
          }
        }).catch(err => { console.log(err) })
    }
    // 是否重复
    const isRepetitiveChange = (e, v) => {
      state.screening.isRepetitive = v
      reload()
      tyleClueReload()
      reloadPopularize()
    }
    // 部门筛选
    const departmentChange = (e, v) => {
      state.screening.orgId = e
      reload()
      tyleClueReload()
      reloadPopularize()
    }
    // 总线索展示日期筛选
    const timeChange = (e, v) => {
      if (e !== 5) {
        state.screening.queryDate = getTimeYMD()
        state.screening.startTime = ''
        state.screening.endTime = ''
        state.chooseTime = []
        reload()
        tyleClueReload()
        reloadPopularize()
      } else {
        const dateDOM = document.querySelector('#dateChoose')
        dateDOM.focus()
      }
    }
    // 总线索自定义日期区间筛选
    const dateChange = (e, v) => {
      state.screening.startTime = v[0]
      state.screening.endTime = v[1]
      state.chooseTime[0] = state.screening.startTime
      state.chooseTime[1] = state.screening.endTime
      state.screening.timeUnit = 5
      state.screening.queryDate = ''
      if (state.screening.startTime === '' || state.screening.endTime === '') {
        state.screening.queryDate = getTimeYMD()
        state.screening.timeUnit = 1
        delete state.screening.startTime
        delete state.screening.endTime
      }
      reload()
      tyleClueReload()
      reloadPopularize()
    }

    // 线索分类统计
    const tyleClueReload = () => {
      typeClue({ ...state.screening })
        .then(res => {
          if (res.code === 10000) {
            state.phoneClueShows = res.data.phoneClueShows
            state.formClueShows = res.data.formClueShows
            state.onlineClueShows = res.data.onlineClueShows
          }
        })
      typeClue({ ...state.screening, isEffective: { label: '否', value: 0 } }) // 线索分类统计-无效线索
        .then(res => {
          if (res.code === 10000) {
            state.infinitePhoneClueShows = res.data.phoneClueShows
            state.infiniteFormClueShows = res.data.formClueShows
            state.infiniteOnlineClueShows = res.data.onlineClueShows
          }
        })
      onClick(state.num)
    }

    const loadData = () => {
      channelList({ channelLevel: 1, isEnable: 1 })
        .then(res => {
          if (res.code === 10000) {
            state.channelList = res.data
            state.channelIds.push(res.data[0].id)
            if (state.chooseType === 1) state.channelId = state.channelIds[0]
            state.channelIdTow = res.data[0].id
            reloadPopularize()
            timeChange()
            setTimeout(() => {
              const DOM = document.querySelectorAll('.ant-radio-button-wrapper')
              DOM[0].classList.add('ant-radio-button-wrapper-checked')
            }, 100)
          }
        })
    }
    onMounted(() => {
      state.orgList = store.state.app.orgList
      if (state.orgList.length > 2) {
        if (state.orgList[0].id !== '') {
          state.orgList.unshift({
            id: '',
            name: '全部'
          })
        }
      }
      if (state.orgList === [] || state.orgList.length < 2) {
        orgList({}).then(res => {
          if (res.code === 10000) {
            store.commit('ORG_LIST', res.data)
            state.orgList = res.data
            if (state.orgList[0].id !== '') {
              state.orgList.unshift({
                id: '',
                name: '全部'
              })
            }
          }
        })
      }
      loadData()
    })

    const onClick = num => {
      const type = { label: '', value: num + 1 }
      if (num) state.num = num
      const DOM = document.querySelectorAll('.total-data > .data')
      DOM.forEach(item => {
        item.classList.remove('check')
      })
      DOM[num].classList.add('check')
      clueChart({ isEffective: { label: '', value: 1 }, ...state.screening, clueType: type }).then(res => {
        if (res.code === 10000) {
          const titleList = []
          res.data.data.forEach(item => {
            titleList.push(item.channelName)
            item.name = item.channelName
            item.data = item.clueNum
            item.type = 'line'
            delete item.channelName
            delete item.clueNum
          })
          state.clueTypeChart = res.data
          state.clueTypeChart.ddd = titleList
          clueType('typeStatistics', state.clueTypeChart)
        }
      }).catch(err => { console.log(err) })
    }
    // 单选 多选
    const chooseChange = (e, v) => {
      if (e.target.value === 2) {
        state.channelIds = []
        state.channelIds[0] = state.channelIdTow
      } else {
        const DOM = document.querySelectorAll('.ant-radio-button-wrapper')
        DOM.forEach(item => {
          item.classList.remove('ant-radio-button-wrapper-checked')
        })
        DOM[0].classList.add('ant-radio-button-wrapper-checked')
        state.channelIds = []
        state.channelId = state.channelIdTow
        state.channelIds[0] = state.channelIdTow
      }
      reloadPopularize()
    }
    // 各渠道推广线索设计
    const reloadPopularize = () => {
      channelClueShows({ ...state.screening, channelIds: state.channelIds })
        .then(res => {
          if (res.code === 10000) {
            state.popularizeData = res.data
            const arrData = []
            res.data.channelSubShowList.forEach(item => {
              arrData.push({ name: item.channelSubName, value: item.totalNum })
            })
            clueStatistics('dataEcharts', arrData, res.data.clueTotalNum, res.data.subChannelProportion)
            const length = arrData.length
            for (let i = 0; i < length; i++) {
              state.colorArr.push(`${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)}`)
            }
          }
        }).catch(err => { console.log(err) })
    }

    const channelIdsChange = (item, index) => {
      const DOM = document.querySelectorAll('.ant-radio-button-wrapper')
      if (!((DOM[index].className).indexOf('ant-radio-button-wrapper-checked') !== -1)) {
        DOM[index].classList.add('ant-radio-button-wrapper-checked')
        if (state.channelIds.indexOf(item.id) !== -1) state.channelIds.splice(state.channelIds.indexOf(item.id), 1)
        state.channelIds.push(item.id)
        reloadPopularize()
      } else {
        if (state.channelIds.length > 1) {
          DOM[index].classList.remove('ant-radio-button-wrapper-checked')
          if (state.channelIds.indexOf(item.id) !== -1) state.channelIds.splice(state.channelIds.indexOf(item.id), 1)
          reloadPopularize()
        } else message.error('无法取消选择,至少选中一个')
      }
    }
    const channelChange = (e, v) => {
      state.channelIds = []
      state.channelIds[0] = state.channelId
      reloadPopularize()
    }

    // 图片判断
    const trend = num => {
      const src = num <= 0 ? require('@/assets/images/marketing/rise.png') : require('@/assets/images/marketing/decline.png')
      return src
    }

    return {
      ...toRefs(state),
      isRepetitiveChange,
      channelIdsChange,
      departmentChange,
      channelChange,
      chooseChange,
      conversionN,
      timeChange,
      dateChange,
      onClick,
      trend
    }
  }
}
</script>

<style lang="less" scoped>@import './index.less';

.a {
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: end;
  flex-direction: column;
  background-color: rgba(88, 109, 201, 0.5);
  justify-content: space-between;
}</style>
