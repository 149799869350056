import { postBodyRequest } from '@/utils/axios'

export function getTotalClue (params) {
  return postBodyRequest('/market/crm/clueStatistics/totalClueShows', params)
}

export function getTotalEcharts (params) {
  return postBodyRequest('/market/crm/clueStatistics/totalClueShowsLineChart', params)
}

export function typeClue (params) {
  return postBodyRequest('/market/crm/clueStatistics/clueTypeShows', params)
}

export function clueChart (params) {
  return postBodyRequest('/market/crm/clueStatistics/clueTypeShowsLineChart', params)
}

export function channelClueShows (params) {
  return postBodyRequest('/market/crm/clueStatistics/channelClueShows', params)
}
// 渠道ID
export function channelList (params) {
  return postBodyRequest('/market/channel/list', params)
}

// 总线索渠道(包一下)
export function totalClueShowsAll (params) {
  return postBodyRequest('/market/crm/clueStatistics/totalClueShowsAll', params)
}
